import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { TextField, Button, Box, Typography, Snackbar } from '@mui/material';
import { updateReferralPartnerEmail } from '../../api'; // Import the API function
import { useData } from '../../DataContext';
import { validateEmail } from '../../utils';

const ReferralPartnerEmail = forwardRef(({ onSave, disabled }, ref) => {
  const [errorMessage, setErrorMessage] = useState(''); // State for error message
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const { offerCalculatorFactor } = useData();
  const [referralPartnerEmail, setReferralPartnerEmail] = useState(offerCalculatorFactor.referralPartnerEmail);

  const handleSaveReferralEmail = async () => {
    if (!referralPartnerEmail) {
      return ""; // Return empty string if no email
    }

    if (referralPartnerEmail && !validateEmail(referralPartnerEmail)) {
      setErrorMessage("Please enter a valid email address."); // Set error message for invalid email
      return "error"; // Return error status
    }

    setErrorMessage(''); // Clear error message if email is valid

    return referralPartnerEmail;
    // API call commented out for now
    // try {
    //   await updateReferralPartnerEmail(offerCalculatorFactor.uuid, referralPartnerEmail);
    //   // setSuccessMessage("Referral partner email saved successfully!");
    //   // setTimeout(() => {
    //   //   setSuccessMessage('');
    //   // }, 3000);
    //   return true; // Return success status
    // } catch (error) {
    //   setErrorMessage("Failed to save the email. Please try again.");
    //   console.error("Error updating referral partner email:", error);
    //   return false; // Return failure status
    // }
  };

  // Expose method to parent component
  useImperativeHandle(ref, () => ({
    handleSaveReferralEmail
  }));

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom align="left">
        Referral Partner Email (Optional)
      </Typography>
      <Box display="flex" alignItems="flex-start" spacing={1}>
        <TextField
          disabled={disabled}
          variant="outlined"
          defaultValue={offerCalculatorFactor.referralPartnerEmail}
          onChange={(e) => setReferralPartnerEmail(e.target.value)}
          error={!!errorMessage}
          placeholder="Enter referral partner email"
          sx={{
            marginRight: 1,
            width: '100%',
            maxWidth: '500px',
            backgroundColor: 'white',
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'white',
            }
          }}
        />
        {/* Save button commented out
        <Button
          variant="contained"
          onClick={handleSaveReferralEmail}
          disabled={disabled}
        >
          Save Referral Email
        </Button> */}
      </Box>
      {errorMessage && (
        <Typography color="error" variant="body2" sx={{ marginTop: 1, textAlign: 'left' }}>
          {errorMessage}
        </Typography>
      )}
      {/* Success message component commented out
      {successMessage && (
        <Typography color="green" variant="body2" sx={{ marginTop: 1, textAlign: 'left' }}>
          {successMessage}
        </Typography>
      )} */}
    </Box>
  );
});

export default ReferralPartnerEmail;

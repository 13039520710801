import {
  Alert, Backdrop,
  Box, Button, CircularProgress,
  Modal,
  Stack, Typography
} from '@mui/material';
import colorsStyle from '../../colors.module.scss';
import { useData } from '../../DataContext';
import { useEffect, useState, useRef } from 'react';
import * as React from 'react';
import { deleteDcoument, documentAttached, submitOffer, uploadDocument } from '../../api';
import { DocumentUploadTable } from './document-upload-table';
import { AdditionalDocumentsTable } from './additional-documents-table';
import ReferralPartnerEmail from './referral-partner-email';
import { validateEmail, validatePhone } from '../../utils';

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: colorsStyle.coconut,
  boxShadow: 24,
  borderRadius: colorsStyle.borderRadius,
  p: 4,
  textAlign: 'center',
  maxHeight: '80vh',
  overflowY: 'auto',
  position: 'relative',
};

const backdropStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1000,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 'inherit',
};

export function UploadModal({ disabled, open, handleClose, isLoading, setIsLoading, submittedResult, setSubmittedResult, updateOffer }) {
  const { offerCalculatorFactor, offerConstants, files, setFiles } = useData();
  const referralEmailRef = useRef(null);
  let [errorMessage, setErrorMessage] = useState({
    show: false,
    succeed: false,
    msg: ''
  });
  const [key, setKey] = useState(0);

  const onAttach = (onlyAttach = true) => {
    const _submit = async () => {
      // First, try to save referral partner email
      const emailSaved = await referralEmailRef.current?.handleSaveReferralEmail();
      // console.log("emailSaved: ", emailSaved)
      if (emailSaved === "error") {
        return false;
      }

      // Check for DL1 and Check file types
      const hasDL1 = files.some(file => file.id === 'DL1');
      const hasCheck = files.some(file => file.id === 'Check');

      if (hasDL1 && !hasCheck) {
        setSubmittedResult({
          submitted: false,
          succeed: false,
          show: true,
          msg: 'If there is a DL1 type file, a Check type file must also be present.'
        });
        return false; // Prevent submission if the condition is not met
      }

      // Check other required fields
      const allFieldsFilled = files.every(file => {
        if(file.isAdditional){
          return true;
        }
        else{
          if(file.id === 'Check'){
            return true;
          }
          return validateEmail(file.email) && validatePhone(file.phone);
        }
      });

      // console.log("allFieldsFilled: ", allFieldsFilled)

      const dlFiles = files
        .filter(file => file.id === 'DL1' || file.id === 'DL2')
        .map(file => ({
          uuid: file.uuid,
          email: file.email,
          phone: file.phone,
          id: file.id
        }));
      console.log("DL files info:", dlFiles);
      console.log("files: ", files)

      if (!allFieldsFilled) {
        setSubmittedResult({
          submitted: false,
          succeed: false,
          show: true,
          msg: 'Please ensure all email and phone fields are filled correctly.'
        });
        return false; // Prevent submission if fields are not filled
      }

      if (onlyAttach) setIsLoading(true);
      console.log(files);
      const updatedFiles = [];
      for (let file of files) {
        console.log(file);
        console.log(`start to upload file: ${file.data.name}`);
        try {
          let uuid = file.uuid;
          if (!uuid || uuid.startsWith('fake-uuid')) {
            uuid = null;
          }
          const resp = await uploadDocument(offerCalculatorFactor.uuid, uuid, file.id, file.data, file.email, file.phone, file.isAdditional);
          console.log(`upload result: ${resp.status}`);
          if (resp.status === 200) {
            updatedFiles.push({
              ...file, data: { name: resp.data.docName }, uuid: resp.data.uuid, status: resp.data.status
            })
          }
        } catch (e) {
          let errorMessage = `upload file error`;
          if (e.response) {
            if (e.response.data.error) {
              errorMessage += `: ${e.response.data.error}`;
            } else {
              errorMessage += `: ${e.response.status} - ${e.response.statusText} `;
            }
          } else if (e.request) {
            errorMessage += ': Network Error';
          }
          setSubmittedResult({
            submitted: false,
            succeed: false,
            msg: errorMessage
          });
          if (onlyAttach) setIsLoading(false);
          return false;
        }
      }
      setFiles(updatedFiles);
      if (onlyAttach) {
        // let backend know document attached
        try {
          await documentAttached(offerCalculatorFactor.uuid, emailSaved);
          updateMessage(true, `attached ${updatedFiles.length} documents successfully`);
        } catch (e) {
          console.log(`document attached error: ${e}`);
        }
        setIsLoading(false);
        setSubmittedResult({
          submitted: false,
          succeed: true,
          msg: '',
        });
      }
      return true;
    };
    return _submit();
  }

  const onSubmit = () => {
    const _submit = async () => {
      setIsLoading(true);
      const uploaded = await onAttach(false);
      if (!uploaded) {
        setIsLoading(false);
        return;
      }

      const dlFiles = files
        .filter(file => file.id === 'DL1' || file.id === 'DL2')
        .map(file => ({
          uuid: file.uuid,
          email: file.email,
          phone: file.phone,
          id: file.id
        }));
      const emailSaved = await referralEmailRef.current?.handleSaveReferralEmail();
      // Submit the offer
      submitOffer(offerCalculatorFactor.uuid, emailSaved, offerCalculatorFactor, dlFiles).then((resp) => {
        setSubmittedResult({
          submitted: true,
          succeed: true,
          msg: 'submitted successfully',
          show: true
        });
        updateOffer();
        handleClose();
      })
        .catch((e) => {
          console.log(e)
          let errorMessage = `submitted error`;
          if (e.response && e.response.data.error) {
            errorMessage += `: ${e.response.data.error}`;
          }
          setSubmittedResult({
            submitted: true,
            succeed: false,
            msg: errorMessage,
            show: true,
          });
        }).finally(() => {
          setIsLoading(false);
        });
    };
    _submit();
  };

  const updateMessage = (succeed, msg) => {
    setErrorMessage({
      show: true,
      succeed: succeed,
      msg: msg
    });
    setKey(prev => prev + 1);
  };

  // Auto-hide alert after 5 seconds
  useEffect(() => {
    let timer;
    if (submittedResult.show && (submittedResult.msg !== '') ) {
      timer = setTimeout(() => {
        setSubmittedResult(prevState => ({
          ...prevState,
          show: false // Hide the alert after 5 seconds
        }));
      }, 5000);
    }
    return () => clearTimeout(timer); // Cleanup the timer on unmount or when submittedResult.show changes
  }, [setSubmittedResult, submittedResult.msg, submittedResult.show]);

  // The prompt message is triggered to display for 5 seconds before disappearing
  useEffect(() => {
    let timer;
    if (errorMessage.show) {
      console.log(`will hide the message in 5 seconds`);
      timer = setTimeout(() => {
        setErrorMessage(prevState => ({
          ...prevState,
          show: false
        }));
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [key, errorMessage.show]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Stack direction={'column'} spacing={3} sx={{ position: 'relative' }}>
          <Stack direction={'column'} spacing={1}>
            <Typography>Documents upload to request contracts</Typography>
            <DocumentUploadTable setIsLoading={setIsLoading} submittedResult={submittedResult} />
          </Stack>

          <Stack direction={'column'} spacing={1}>
            <Typography>Documents upload to requisite approval conditions</Typography>
            <AdditionalDocumentsTable setIsLoading={setIsLoading} />
          </Stack>

          {errorMessage.show && (
            <Alert variant="filled" severity={errorMessage.succeed ? 'success' : 'error'}>
              {errorMessage.msg}
            </Alert>
          )}

          <Box display="flex" alignItems="flex-end" justifyContent="space-between">
            <ReferralPartnerEmail
              ref={referralEmailRef}
              setIsLoading={setIsLoading}
              disabled={offerCalculatorFactor.status === 'accepted'}
              sx={{ width: '300px', maxWidth: '500px' }}
            />
            <Stack direction={'row'} justifyContent={'flex-end'} spacing={3}>
              <Button
                disabled={disabled}
                variant="contained"
                color={'info'}
                sx={{ fontWeight: 'bold' }}
                onClick={onAttach}
              >
                ATTACH
              </Button>
              <Button
                variant="contained"
                color={'info'}
                sx={{ fontWeight: 'bold' }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                disabled={disabled}
                variant="contained"
                color={'secondary'}
                size={'large'}
                sx={{ fontWeight: 'bold' }}
                onClick={onSubmit}
              >
                {disabled ? 'Submitted' : 'Submit'}
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
}

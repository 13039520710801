// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from '../styles/app.module.scss';
import CalculatorPanel from '../sections/calculator/calculator-panel';
import Offer from '../sections/calculator/offer';
import { Alert, Backdrop, CircularProgress, Skeleton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useData } from '../DataContext';
import { getDocuments, getOffer } from '../api';
import RepaymentTable from '../sections/calculator/repayment-table';
import ApprovalList from '../sections/calculator/approval-list';
import { AdditionalDocumentsTable } from '../sections/calculator/additional-documents-table';
import * as React from 'react';
import { AllAdditionalDocumentsTable } from '../sections/calculator/all-additional-documents-table';


export function Calculator() {
  let [err, setErr] = useState('');
  let [isLoading, setIsLoading] = useState(false);
  let [isInitializing, setIsInitializing] = useState(true);
  let { setOfferCalculatorFactor, offerCalculatorFactor, setFiles } = useData();

  let { uuid } = useParams();
  let [submittedResult, setSubmittedResult] = useState({
    submitted: false,
    succeed: false,
    msg: ''
  });

  const initial = (firstTime=false) => {
    if (firstTime)
      setIsInitializing(true);
    getOffer(uuid).then((resp) => {
      setErr('');
      setOfferCalculatorFactor(resp.data);
      getDocuments(uuid).then((resp) => {
        const files = []
        for (let file of resp.data.data) {
          files.push({
            uuid: file.uuid,
            id: file.docType,
            status: file.status,
            email: file.email,
            phone: file.phone,
            data: {
              name: file.docName
            },
            isAdditional: file.isAdditional
          })
        }
        setFiles(files);
      }).catch((err) => {
        console.log(err)
      })
    }).catch((error) => {
      console.log(error);
      if (error.response) {
        if (error.response.status === 403) {
          setErr('This offer is expired');
        } else if (error.response.status === 404) {
          setErr('This Offer is not existed');
        }
      }
    }).finally(() => {
      if (firstTime)
        setIsInitializing(false);
    });
  }

  useEffect(() => {
    initial(true)
  }, []);



  if (err) {
    return (
      <Stack alignContent="center">
        <Alert variant="filled" severity="error">
          {err}
        </Alert>
      </Stack>
    );
  }

  if (isInitializing) {
    return (
      <Stack justifyItems={'center'} alignItems={'center'}>
        <Skeleton variant="rounded" width={'800px'} height={'600px'} />
      </Stack>
    );
  }

  return (
    <>
      <Backdrop open={isLoading} sx={{ color: '#fff', zIndex: 999999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles.main}>
        {submittedResult.submitted && (
          <Alert variant="filled" severity={submittedResult.succeed ? 'success' : 'error'}>
            {submittedResult.msg}
          </Alert>
        )}
        <Stack direction="row" justifyContent={'space-between'}>
          <CalculatorPanel className={styles.calculator} />
          <Offer className={styles.offer} submittedResult={submittedResult} setSubmittedResult={setSubmittedResult} setIsLoading={setIsLoading} updateOffer={initial} isLoading={isLoading}/>
        </Stack>
        <div className={styles.agreement}>
          <p>THIS IS A CONDITIONAL OFFER WHICH MAYBE CANCELED BY THE LENDER IF REQUISITE ITEMS ARE NOT SATISFIED.</p>
          <p className={styles.title}>STANDARD LOANS/ADVANCE REQUISITE APPROVAL LIST</p>
          <ApprovalList style={{ width: '900px', margin: 'auto' }} />
          <br /><br />
          <hr />
          <p className={styles.title}>CONFIRMED OUTSTANDING CONDITIONS TO FUND</p>
          <AllAdditionalDocumentsTable style={{ width: '450px', margin: 'auto' }}/>
          <br /><br />
          <hr />
          <br />
          <p className={styles.title}>PREPAYMENT DISCOUNT SCHEDULE</p>
          <p><em>SOME STATES MAY NOT PERMIT PREPAYMENT DISCOUNTS</em></p>
          <RepaymentTable style={{ width: '500px', margin: 'auto' }} />
        </div>
      </div>
    </>
  );
}

export default Calculator;

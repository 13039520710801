import validator from "validator";

export function formatUSD(value, minimumFractionDigits = 0) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: minimumFractionDigits
        // maximumFractionDigits: 0
    }).format(value);
}


export function getCommissionFee(loadAmount, commissionPercent) {
    if (!loadAmount || !commissionPercent) {
        return 0;
    }
    return loadAmount * commissionPercent * 0.01;
}

export function getOriginationFee(loadAmount, originationPercent) {
    if (!loadAmount || !originationPercent) {
        return 0;
    }
    return loadAmount * originationPercent * 0.01;
}

export function getRepayment(loadAmount, factor) {
    if (!loadAmount || !factor) {
        return 0;
    }
    return loadAmount * factor;
}

export function getPayment(repayment, term, freq) {
    if (!repayment || !term || !freq) {
        return 0;
    }
    const numberOfPayment = term * freq;
    return repayment / numberOfPayment;
}

export function getNetFunding(loadAmount, originationPercent, thirdPartyInternalBalance) {
    if (!loadAmount) {
        return 0;
    }
    const netFunding = loadAmount - getOriginationFee(loadAmount, originationPercent) - thirdPartyInternalBalance
    return netFunding > 0 ? netFunding : 0;
}

export function formatFloat(num) {
  const parts = num.toString().split(".");
  if (parts.length === 2 && parts[1].length >= 3) {
    let rounded = Math.round(num * 1000) / 1000;
    let roundedStr = rounded.toString();
    return parseFloat(roundedStr);
  }
  return num;
}

export function getErrorMessage(title, e) {
  let errorMessage = title
  if (e.response) {
    if (e.response.data.error) {
      errorMessage += `: ${e.response.data.error}`;
    } else {
      errorMessage += `: ${e.response.status} - ${e.response.statusText} `;
    }
  } else if (e.request) {
    errorMessage += ': Network Error';
  }
  return errorMessage;
}

export function openDocument(offerUuid, docUuid) {
  return `${process.env.REACT_APP_API_URL}/offers/${offerUuid}/documents/${docUuid}`;
}
export const validateEmail = (email) => {
  return email && validator.isEmail(String(email));
};

export const validatePhone = (phone) => {
  return phone && validator.isMobilePhone(String(phone), 'en-US');
};


export function genID(length) {
  return (Math.random().toString(36).slice(2) + Date.now().toString(36)).slice(0, length);
}

import { Alert, IconButton, SvgIcon, Tooltip, FormControl, Select, MenuItem } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import * as React from 'react';
import { useData } from '../../DataContext';
import TableRow from '@mui/material/TableRow';
import colorsStyle from '../../colors.module.scss';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useRef, useState } from 'react';
import { VisuallyHiddenInput } from '../../components/upload-button';
import { getColorByStatus } from './document-upload-table';
import { StyledTableCell, StyledTableRow } from './repayment-table';
import { deleteDcoument, uploadDocument } from '../../api';
import { genID, getErrorMessage } from '../../utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { openDocument } from '../../utils';

export function AdditionalDocumentsTable({ style, readonly, setIsLoading }) {
  const { offerCalculatorFactor, files, setFiles } = useData();
  const [filesMap, setFilesMap] = useState(new Map());
  const [selectedType, setSelectedType] = useState('');
  const [selectedTypeToDelete, setSelectedTypeToDelete] = useState('');
  let [submittedResult, setSubmittedResult] = useState({
    show: false,
    succeed: false,
    msg: ''
  });
  const [key, setKey] = useState(0);

  useEffect(() => {
    console.log('Requirements:', offerCalculatorFactor.requirements);
  }, [offerCalculatorFactor.requirements]);

  // Initialize filesMap when component mounts or when files change
  useEffect(() => {
    const newFilesMap = new Map();
    console.log("files: ", files)
    files.forEach(file => {
      if (file.isAdditional && file.id) {
        newFilesMap.set(file.uuid, file);
      }
    });
    setFilesMap(newFilesMap);
  }, [files]);

  // The prompt message is triggered to display for 5 seconds before disappearing
  useEffect(() => {
    let timer;
    if (submittedResult.show && submittedResult.succeed) {
      console.log(`will hide the message in 5 seconds`);
      timer = setTimeout(() => {
        setSubmittedResult(prevState => ({
          ...prevState,
          show: false
        }));
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [key, submittedResult.succeed]);

  const onDeleteFile = async (fileUuid) => {
    const updatedFiles = [...files];
    const fileToDelete = filesMap.get(fileUuid);

    if (!fileToDelete) {
      return;
    }

    try {
      setIsLoading(true);
      if (fileToDelete.uuid) {
        await deleteDcoument(offerCalculatorFactor.uuid, fileToDelete.uuid);
        updateMessage(true, `File ${fileToDelete.data.name} deleted successfully`);
      }

      // Remove from files array
      const index = updatedFiles.findIndex(f => f.uuid === fileUuid);
      if (index !== -1) {
        updatedFiles.splice(index, 1);
      }

      // Remove from filesMap
      const newFilesMap = new Map(filesMap);
      newFilesMap.delete(fileUuid);

      // Check if there are remaining files of the same type
      const hasRemainingFilesOfType = Array.from(newFilesMap.values())
        .some(file => file.id === fileToDelete.id);

      // If no files of this type remain and it's the currently selected type, clear the selection
      if (!hasRemainingFilesOfType && selectedTypeToDelete === fileToDelete.id) {
        setSelectedTypeToDelete('');
      }

      setFiles(updatedFiles);
    } catch (e) {
      updateMessage(false, getErrorMessage(`Failed to delete file ${fileToDelete.data.name}`, e));
    } finally {
      setIsLoading(false);
    }
  };

  const onFileUpload = async (event) => {
    if (!selectedType) {
      updateMessage(false, 'Please select a document type first');
      return;
    }

    const uploadedFiles = Array.from(event.target.files);
    if (!uploadedFiles.length) return;

    const updatedFiles = [...files];
    const newFilesMap = new Map(filesMap);

    try {
      setIsLoading(true);

      for (let i = 0; i < uploadedFiles.length; i++) {
        const uploadedFile = uploadedFiles[i];
        // Generate a more reliable unique ID
        const newFile = {
          id: selectedType,  // Ensure docType is set
          data: uploadedFile,
          isAdditional: true,
          uuid: `fake-uuid-${i}-${genID(16)}-${uploadedFile.name}`
        };

        updatedFiles.push(newFile);
        newFilesMap.set(newFile.uuid, newFile);
      }

      setFiles(updatedFiles);
      // updateMessage(true, `${uploadedFiles.length} files uploaded successfully`);
      setSelectedType('');
    } catch (e) {
      // updateMessage(false, getErrorMessage(`Failed to upload files`, e));
    } finally {
      setIsLoading(false);
      event.target.value = '';
    }
  };

  const updateMessage = (succeed, msg) => {
    setSubmittedResult({
      show: true,
      succeed: succeed,
      msg: msg
    });
    setKey(prev => prev + 1);
  }

  const isDisabled = (status) => {
    return status === 'Approved';
  }

  // Check if a document type has already been uploaded
  const isDocTypeUploaded = (docType) => {
    return Array.from(filesMap.values()).some(file => file.docType === docType);
  };

  // Get list of uploaded document types (unique)
  const getUploadedDocTypes = () => {
    const types = new Set();
    filesMap.forEach(file => {
      if (file.id) {
        types.add(file.id);
      }
    });
    return Array.from(types);
  };

  // Delete all files of the same type
  const onDeleteAllOfType = async () => {
    if (!selectedTypeToDelete) {
      updateMessage(false, 'Please select a document type first');
      return;
    }

    const filesToDelete = Array.from(filesMap.values())
      .filter(file => file.id === selectedTypeToDelete);
    if (filesToDelete.length === 0) {
      updateMessage(false, 'No files found of this type');
      return;
    }

    try {
      setIsLoading(true);
      const updatedFiles = [...files];

      for (const fileToDelete of filesToDelete) {
        if (fileToDelete.uuid) {
          await deleteDcoument(offerCalculatorFactor.uuid, fileToDelete.uuid);
        }
        // Remove from files array
        const index = updatedFiles.findIndex(f => f.uuid === fileToDelete.uuid);
        if (index !== -1) {
          updatedFiles.splice(index, 1);
        }
      }

      // Update filesMap
      const newFilesMap = new Map(filesMap);
      filesToDelete.forEach(file => newFilesMap.delete(file.uuid));

      setFiles(updatedFiles);
      updateMessage(true, `Deleted all files of type ${selectedTypeToDelete}`);
      setSelectedTypeToDelete('');
    } catch (e) {
      updateMessage(false, getErrorMessage(`Failed to delete files of type ${selectedTypeToDelete}`, e));
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviewFile = (file) => {
    if (file && file.uuid) {
      if (file.uuid.startsWith('fake-uuid')) {
        const fileType = file.data.type;
        const url = URL.createObjectURL(file.data);
        const newWindow = window.open("", "_blank");

        if (fileType.startsWith("image/")) {
          newWindow.document.write(`<img src="${url}" style="max-width:100%;">`);
        } else if (fileType.startsWith("video/")) {
          newWindow.document.write(`<video src="${url}" controls style="max-width:100%;"></video>`);
        } else if (fileType.startsWith("audio/")) {
          newWindow.document.write(`<audio src="${url}" controls></audio>`);
        } else if (fileType.startsWith("application/pdf")) {
          newWindow.document.write(`<iframe src="${url}" style="width:100%; height:100vh;"></iframe>`);
        } else {
          newWindow.document.write(`<a href="${url}" download="${file.data.name}">Download ${file.data.name}</a>`);
        }
      } else {
        const fileUrl = openDocument(offerCalculatorFactor.uuid, file.uuid);
        window.open(fileUrl, '_blank');
      }
    }
  };

  return (
    <>
      {!readonly && (
        <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', gap: '16px' }}>
          <FormControl style={{ minWidth: '200px', maxWidth: '300px' }}>
            <Select
              displayEmpty
              size="small"
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              renderValue={(selected) => {
                if (!selected) return <em>Select type to upload...</em>;
                return selected;
              }}
            >
              {offerCalculatorFactor.requirements
                .filter(item => item.enabled)
                .map((item, index) => (
                  <MenuItem key={index} value={item.field}>{item.field}</MenuItem>
                ))}
            </Select>
          </FormControl>

          <IconButton
            component="label"
            variant="contained"
            disabled={!selectedType || offerCalculatorFactor.status === 'accepted'}
            sx={{
              color: colorsStyle.dropboxBlue,
              bgcolor: 'rgba(0, 0, 0, 0.04)',
              '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.08)' }
            }}
          >
            <VisuallyHiddenInput
              type="file"
              onChange={onFileUpload}
              multiple={true}
            />
            <SvgIcon>
              <UploadIcon />
            </SvgIcon>
          </IconButton>

          <FormControl style={{ minWidth: '200px' }}>
            <Select
              displayEmpty
              size="small"
              value={selectedTypeToDelete}
              onChange={(e) => setSelectedTypeToDelete(e.target.value)}
              renderValue={(selected) => {
                if (!selected) return <em>Select type to delete...</em>;
                return selected;
              }}
            >
              {getUploadedDocTypes().map((docType, index) => (
                <MenuItem key={index} value={docType}>{docType}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <IconButton
            onClick={onDeleteAllOfType}
            disabled={!selectedTypeToDelete || offerCalculatorFactor.status === 'accepted'}
            sx={{
              color: 'red',
              bgcolor: 'rgba(0, 0, 0, 0.04)',
              '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.08)' }
            }}
          >
            <Tooltip title={`Delete all files of selected type`}>
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </div>
      )}

      <TableContainer component={Paper} style={style}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>File Type</TableCell>
              <TableCell>File Name</TableCell>
              <TableCell>Review Status</TableCell>
              {!readonly && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(filesMap.values()).map((file, index) => (
              <TableRow key={index}>
                <TableCell>{file.id}</TableCell>
                <TableCell>
                  <Tooltip title={file.data?.name}>
                    <span
                      style={{
                        display: 'inline-block',
                        maxWidth: '150px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        verticalAlign: 'middle'
                      }}
                    >
                      {file.data?.name}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <span style={{
                    color: getColorByStatus(file.status),
                    fontWeight: 'bold'
                  }}>{file.status}</span>
                </TableCell>
                {!readonly && (
                  <TableCell>
                    <Tooltip title="Preview file">
                      <IconButton
                        aria-label="preview"
                        sx={{ color: 'primary.main' }}
                        onClick={() => handlePreviewFile(file)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={`delete ${file.id}`}>
                      <IconButton
                        aria-label="delete"
                        sx={{ color: 'red' }}
                        onClick={() => onDeleteFile(file.uuid)}
                        disabled={file.status === 'Approved' || offerCalculatorFactor.status === 'accepted'}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {submittedResult.show && (
        <Alert variant="filled" severity={submittedResult.succeed ? 'success' : 'error'}>
          {submittedResult.msg}
        </Alert>
      )}
    </>
  );
}

import React, { useEffect, useState, useCallback } from 'react';
import styles from '../../styles/calculator.module.scss';
import {
  MenuItem,
  Select,
  Stack
} from '@mui/material';
import { CalculatorSlider } from './calculator-slider';
import { useData } from '../../DataContext';
import { formatUSD, getCommissionFee } from '../../utils';
import { CalculateType } from '../../constants';


export function CalculatorPanel(props) {
  const { className } = props;
  const { offerCalculatorFactor, offerConstants, setOfferCalculatorFactor } = useData();
  // console.log("offerCalculatorFactor", offerCalculatorFactor)
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    // initial user selected offer
    if (offerCalculatorFactor.status === 'accepted') {
      setDisabled(true);
    }
  }, [offerCalculatorFactor]);

  return (
    <Stack className={className} spacing={1.5}>
      <div className={[styles.panel, styles.info].join(' ')}>
        <div className={styles.row}>
          <div className={styles.title}>
            Merchant:
          </div>
          <div className={styles.value}>
            {offerCalculatorFactor.merchant}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>
            Referral Partner:
          </div>
          <div className={styles.value}>
            {offerCalculatorFactor.referralPartner}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>
            Origination Fee:
          </div>
          <div className={styles.value}>
            {offerCalculatorFactor.origination}%
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>
            Payment Frequency:
          </div>
          <div className={styles.value}>
            <Select
              disabled='true'
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={offerCalculatorFactor.frequency}
              SelectDisplayProps={{
                style: {
                  padding: '10px 32px 10px 14px',
                  color: 'grey',
                  textAlign: 'right'
                }
              }}
            >
              {offerConstants.paymentFrequencies?.map((item, index) => (
                <MenuItem key={index} value={item}>{item}</MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className={styles.panel}>
        <CalculatorSlider title={CalculateType.LoadAmount} min={offerCalculatorFactor.loadAmountMin}
                          max={offerCalculatorFactor.loadAmountMax}
                          minLabel={formatUSD(offerCalculatorFactor.loadAmountMin)}
                          maxLabel={formatUSD(offerCalculatorFactor.loadAmountMax)}
                          defaultValue={offerCalculatorFactor.loadAmount}
                          inputFormat={'dollar'} step={1000} disabled={disabled}/>
      </div>
      <div className={styles.panel}>
        <CalculatorSlider title={CalculateType.Term} min={offerCalculatorFactor.termMin}
                          max={offerCalculatorFactor.termMax} minLabel={`${offerCalculatorFactor.termMin}mo.`}
                          maxLabel={`${offerCalculatorFactor.termMax}mo.`}
                          defaultValue={offerCalculatorFactor.term} inputFormat={'number'}
                          step={1}
                          disabled={disabled}
        />
      </div>
      <div className={styles.panel}>
        <CalculatorSlider title={CalculateType.Commission} min={offerCalculatorFactor.commissionMin}
                          max={offerCalculatorFactor.commissionMax}
                          minLabel={`${offerCalculatorFactor.commissionMin}%`}
                          maxLabel={`${offerCalculatorFactor.commissionMax}%`}
                          defaultValue={offerCalculatorFactor.commission}
                          inputFormat={'percentage'} step={0.5}
                          disabled={disabled}
                          helperText={formatUSD(getCommissionFee(offerCalculatorFactor.loadAmount, offerCalculatorFactor.commission)) || ''}
        />
      </div>
    </Stack>
  );
}


export default CalculatorPanel;

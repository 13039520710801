import {
  Alert,
  FormControl,
  IconButton,
  Input,
  MenuItem,
  Select, SvgIcon, TextField,
  Tooltip,
  Typography
} from '@mui/material';
import colorsStyle from '../../colors.module.scss';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { useData } from '../../DataContext';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import styles from '../../styles/offer.module.scss';
import InputFileUpload, { VisuallyHiddenInput } from '../../components/upload-button';
import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import { TextMaskCustom } from '../../components/text-filed-formatter';
import { deleteDcoument, uploadDocument } from '../../api';
import { genID, getErrorMessage, validateEmail } from '../../utils';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { openDocument } from '../../utils';


export function getColorByStatus(status) {
  switch (status) {
    case 'Denied':
      return 'red';
    case 'Approved':
      return 'green';
    case 'Pending':
      return 'orange';
    default:
      return 'black';
  }
};

export function DocumentUploadTable({ setIsLoading, submittedResult }) {
  const { offerCalculatorFactor, offerConstants, files, setFiles } = useData();
  const [documentTypes, setDocumentTypes] = useState(offerConstants.documentTypes);
  const [selected, setSelected] = useState(new Set());
  const [hasFiles, setHasFiles] = useState(false);
  const [filesMap, setFilesMap] = useState(new Map());
  let [errorMessage, setErrorMessage] = useState({
    show: false,
    succeed: false,
    msg: ''
  });
  const [key, setKey] = useState(0);
  const [emailOrPhoneUpdated, setEmailOrPhoneUpdated] = useState({
    email: false,
    phone: false
  });
  const [selectedUploadType, setSelectedUploadType] = useState('');

  // Initialize filesMap when component mounts or when files change
  useEffect(() => {
    const newFilesMap = new Map();
    console.log("files: ", files)
    files.forEach(file => {
      if (!file.isAdditional && file.id) {
        console.log("file: ", file.uuid)
        const newFile = {...file};
        newFilesMap.set(file.uuid, newFile);
      }
    });
    setFilesMap(newFilesMap);
    console.log("filesMap: ", newFilesMap)
  }, [files]);

  // The prompt message is triggered to display for 5 seconds before disappearing
  useEffect(() => {
    let timer;
    if (errorMessage.show) {
      console.log(`will hide the message in 5 seconds`);
      timer = setTimeout(() => {
        setErrorMessage(prevState => ({
          ...prevState,
          show: false
        }));
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [key, errorMessage.show]);

  // Update file information with response data
  const updateFileInfo = (file, respData) => {
    file.status = respData.status;
    file.data = { name: respData.docName };
    file.uuid = respData.uuid;
    file.id = respData.docType;
    file.status = respData.status;
    file.email = respData.email;
    file.phone = respData.phone;
    file.data = {
      name: respData.docName
    };
    file.isAdditional = respData.isAdditional;
  };

  const onFileUpload = async (event) => {
    const uploadedFiles = Array.from(event.target.files);
    const updatedFiles = [...files];
    const newFilesMap = new Map(filesMap);

    try {
      setIsLoading(true);

      if (uploadedFiles && uploadedFiles.length > 0) {
        for (let i = 0; i < uploadedFiles.length; i++) {
          const uploadedFile = uploadedFiles[i];

          const newFile = {
            id: selectedUploadType,
            data: uploadedFile,
            isAdditional: false,
            uuid: `fake-uuid-${i}-${genID(16)}-${uploadedFile.name}`
          };

          updatedFiles.push(newFile);
          newFilesMap.set(newFile.uuid, {...newFile});
        }
        setFiles(updatedFiles);
        setSelectedUploadType('');

        console.log('Updated file list:', updatedFiles);
      } else {
        console.error('No files uploaded or uploadedFiles is not iterable');
      }
    } catch (e) {
      updateMessage(false, getErrorMessage(`Failed to upload files`, e));
    } finally {
      setIsLoading(false);
      event.target.value = '';
    }
  };

  const onReplaceFile = async (uuid, event) => {
    const updatedFiles = [...files];
    const uploadedFile = event.target.files[0];
    for (const updatedFile of updatedFiles) {
      if (updatedFile.uuid === uuid) {
        try {
          setIsLoading(true);
          const resp = await uploadDocument(offerCalculatorFactor.uuid, updatedFile.uuid, updatedFile.id, uploadedFile, updatedFile.email, updatedFile.phone, false);
          updateFileInfo(updatedFile, resp.data);
          setFiles(updatedFiles);
          event.target.value = '';
          updateMessage(true, `Upload/Update File ${updatedFile.data.name} Successfully`);
        } catch (e) {
          updateMessage(false, getErrorMessage(`Upload/Update File ${updatedFile.data.name} Error`, e));
        } finally {
          setIsLoading(false);
        }
        break;
      }
    }
  };

  const onSelectedFileType = async (index, value) => {
    const updatedFiles = [...files];
    const updatedSelected = new Set(selected);
    const theFile = updatedFiles[index];
    const oldValue = theFile.id;
    theFile.id = value;
    updatedSelected.delete(oldValue);
    updatedSelected.add(value);

    try {
      setIsLoading(true);
      const resp = await uploadDocument(offerCalculatorFactor.uuid, theFile.uuid, theFile.id, theFile.data, theFile.email, theFile.phone, theFile.isAdditional);
      updateFileInfo(theFile, resp.data);
      setFiles(updatedFiles);
      setSelected(updatedSelected);
      updateMessage(true, `Upload/Update File ${theFile.data.name} Successfully`);
    } catch (e) {
      console.log(`upload file error: ${e}`);
      updateMessage(false, getErrorMessage(`Upload/Update File ${theFile.data.name} Error`, e));
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteFile = async (fileUuId) => {
    const updatedFiles = [...files];
    const fileToDelete = filesMap.get(fileUuId);

    if (!fileToDelete) {
      return;
    }

    try {
      setIsLoading(true);
      await deleteDcoument(offerCalculatorFactor.uuid, fileToDelete.uuid);
      updateMessage(true, `Delete File ${fileToDelete.data.name} Successfully`);

      // Remove the file from the files array
      const index = updatedFiles.findIndex(f => f.uuid === fileUuId);
      if (index !== -1) {
        updatedFiles.splice(index, 1);
      }

      // Remove the file from the filesMap
      const newFilesMap = new Map(filesMap);
      newFilesMap.delete(fileUuId);

      setFiles(updatedFiles);

      // Check if the deleted file type is still in the files array
      // const hasRemainingFilesOfType = updatedFiles.some(f => f.id === fileToDelete.id);
      // if (!hasRemainingFilesOfType) {
      //   // Split the docType into id and name
      //   // const [name, id] = fileToDelete.docType.split('(');
      //   // const cleanId = id.replace(')', ''); // Remove the closing parenthesis

      //   // Re-add the file type back to the dropdown
      //   setDocumentTypes(prevTypes => [
      //     ...prevTypes,
      //     { id: fileToDelete.id, name: name.trim() } // Assuming the name is the part before the parentheses
      //   ]);
      // }
    } catch (e) {
      updateMessage(false, getErrorMessage(`Delete File ${fileToDelete.data.name} Error`, e));
    } finally {
      setIsLoading(false);
    }
  };

  // Handle email and phone changes
  const onEmailPhoneChange = (fileUuid, emailOrPhone, event) => {
    const theFileInMap = filesMap.get(fileUuid);
    theFileInMap[emailOrPhone] = event.target.value;
    const updatedFilesMap = new Map(filesMap);
    updatedFilesMap.set(fileUuid, theFileInMap);

    const updatedFiles = [...files];
    const index = updatedFiles.findIndex(f => f.uuid === fileUuid);
    const theFile = updatedFiles[index];
    theFile[emailOrPhone] = event.target.value;
    updatedFiles[index] = {...theFile};
    setFiles(updatedFiles);
  };

  const onEmailPhoneBlur = async (index, emailOrPhone) => {
    const updatedFiles = [...files];
    const theFile = updatedFiles[index];

    // Check if both email and phone are filled correctly
    const isEmailValid = validateEmail(theFile.email);
    const isPhoneValid = theFile.phone && theFile.phone.trim() !== ''; // Ensure phone is not empty

    if (!isEmailValid || !isPhoneValid) {
      updateMessage(false, "Please ensure both email and phone are filled correctly."); // Show error message
      return; // Prevent further execution if validation fails
    }

    // Proceed with the update if both fields are valid
    try {
      setIsLoading(true);
      const resp = await uploadDocument(
        offerCalculatorFactor.uuid,
        theFile.uuid,
        theFile.id,
        theFile.data,
        theFile.email,
        theFile.phone,
        theFile.isAdditional
      );
      updateFileInfo(theFile, resp.data);
      updateMessage(true, `Upload/Update File ${theFile.data.name} Successfully`);
    } catch (e) {
      const errorMessage = getErrorMessage(`Upload/Update File ${theFile.data.name} Error`, e);
      updateMessage(false, errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  // Save email and phone information
  const saveEmailAndPhone = async (fileUuId) => {
    const theFile = filesMap.get(fileUuId);
    console.log("saveEmailAndPhone:", theFile)
    if (!theFile) {
      return;
    }

    // Validate email and phone
    const isEmailValid = validateEmail(theFile.email);
    const isPhoneValid = theFile.phone && theFile.phone.trim() !== ''; // Ensure phone is not empty

    if (!isEmailValid || !isPhoneValid) {
      updateMessage(false, "Please ensure both email and phone are filled correctly."); // Show error message
      return; // Prevent further execution if validation fails
    }

    // Proceed with the update if both fields are valid
    try {
      setIsLoading(true);
      const resp = await uploadDocument(
        offerCalculatorFactor.uuid,
        theFile.uuid,
        theFile.id,
        theFile.data,
        theFile.email,
        theFile.phone,
        theFile.isAdditional
      );
      updateFileInfo(theFile, resp.data);

      const updatedFiles = [...files];

      const index = updatedFiles.findIndex(f => f.uuid === fileUuId);
      updatedFiles[index] = {...theFile};

      setFiles(updatedFiles)
      updateMessage(true, `Email and phone for ${theFile.data.name} saved successfully.`);
    } catch (e) {
      const errorMessage = getErrorMessage(`Error saving email and phone for ${theFile.data.name}`, e);
      updateMessage(false, errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  // Update message display
  const updateMessage = (succeed, msg) => {
    setErrorMessage({
      show: true,
      succeed: succeed,
      msg: msg
    });
    setKey(prev => prev + 1);
  };

  // Handle file preview
  const handlePreviewFile = (file) => {
    if (file && file.uuid) {
      const fileUuid = file.uuid; // Get the file UUID from the file objectU
      if (fileUuid.startsWith('fake-uuid')) {
        const fileType = file.data.type;
        const url = URL.createObjectURL(file.data);
        const newWindow = window.open("", "_blank");

        if (fileType.startsWith("image/")) {
          newWindow.document.write(`<img src="${url}" style="max-width:100%;">`);
        } else if (fileType.startsWith("video/")) {
          newWindow.document.write(`<video src="${url}" controls style="max-width:100%;"></video>`);
        } else if (fileType.startsWith("audio/")) {
          newWindow.document.write(`<audio src="${url}" controls></audio>`);
        } else if (fileType.startsWith("application/pdf")) {
          newWindow.document.write(`<iframe src="${url}" style="width:100%; height:100vh;"></iframe>`);
        } else {
          newWindow.document.write(`<a href="${url}" download="${file.data.name}">Download ${file.data.name}</a>`);
        }
      } else {
        const fileUrl = openDocument(offerCalculatorFactor.uuid, fileUuid);
        window.open(fileUrl, '_blank');
      }
    }

  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '20px', flexWrap: 'nowrap' }}>
        <FormControl style={{ minWidth: '200px', maxWidth: '300px' }}>
          <Select
            displayEmpty
            size="small"
            value={selectedUploadType}
            onChange={(e) => setSelectedUploadType(e.target.value)}
            renderValue={(selected) => {
              if (!selected) return <em>Select type to upload...</em>;
              return selected;
            }}
          >
            {documentTypes.map((item, index) => (
              <MenuItem key={index} value={`${item.id}`}>{`${item.name}(${item.id})`}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <IconButton
          component="label"
          variant="contained"
          disabled={selectedUploadType === '' || offerCalculatorFactor.status === 'accepted'}
          sx={{ color: colorsStyle.dropboxBlue }}
        >
          <VisuallyHiddenInput type="file" onChange={onFileUpload} accept=".pdf,.png,.jpg,.jpeg" />
          <SvgIcon>
            <UploadIcon />
          </SvgIcon>
        </IconButton>

        <Typography
          variant="body1"
          sx={{
            color: 'error.main',
            fontStyle: 'italic',
            fontWeight: 'bold',
            textAlign: 'left',
            width: '100%',
            display: 'block',
            lineHeight: 1.5
          }}
        >
          If a DL1 document is present, it is crucial to also upload a voided check; otherwise, you will not be able to submit the offer.
        </Typography>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>File Type</TableCell>
              {/* <TableCell>Replace</TableCell> */}
              <TableCell>File Name</TableCell>
              <TableCell>Review Status</TableCell>
              <TableCell>Email(<span style={{ color: 'red' }}>*Required</span>)</TableCell>
              <TableCell>Phone(<span style={{ color: 'red' }}>*Required</span>)</TableCell>
              <TableCell sx={{ minWidth: '110px' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(filesMap.values()).map((file, index) => (
              <TableRow key={file.uuid}>
                <TableCell>{file.id}</TableCell>
                {/* <TableCell>
                  <Tooltip title={`replace ${file.id}`}>
                    <IconButton component="label" variant="contained" disabled={file.status === 'Approved'}>
                      <VisuallyHiddenInput type="file" onChange={(e) => onReplaceFile(file.uuid, e)} />
                      <SvgIcon>
                        <UploadIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </TableCell> */}
                <TableCell>
                  <Tooltip title={file.data?.name}>
                    <span
                      style={{
                        display: 'inline-block',
                        maxWidth: '150px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        verticalAlign: 'middle'
                      }}
                    >
                      {file.data?.name}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <span style={{ color: getColorByStatus(file.status), fontWeight: 'bold' }}>{file.status}</span>
                </TableCell>
                <TableCell>
                  {file.id !== 'Check' && (
                    <TextField
                      required
                      variant="standard"
                      disabled={file.status === 'Approved' || offerCalculatorFactor.status === 'accepted'}
                      defaultValue={file.email || ''}
                      onChange={(e) => onEmailPhoneChange(file.uuid, 'email', e)}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {file.id !== 'Check' && (
                    <TextField
                      required
                      variant="standard"
                      disabled={file.status === 'Approved' || offerCalculatorFactor.status === 'accepted'}
                      defaultValue={String(file.phone) || ''}
                      onChange={(e) => onEmailPhoneChange(file.uuid, 'phone', e)}
                      InputProps={{
                        inputComponent: TextMaskCustom,
                        inputProps: {
                          name: 'phone',
                        },
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {/* <IconButton
                    aria-label="save"
                    sx={{ color: 'green' }}
                    onClick={() => saveEmailAndPhone(file.uuid)}
                    disabled={file.id === 'Check' || file.status === 'Approved' || offerCalculatorFactor.status === 'accepted'}
                  >
                    <SaveIcon />
                  </IconButton> */}

                  <Tooltip title="Preview file">
                    <IconButton
                      aria-label="preview"
                      sx={{ color: 'primary.main' }}
                      onClick={() => handlePreviewFile(file)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={`delete ${file.id}`}>
                    <span>
                      <IconButton
                        aria-label="delete"
                        sx={{ color: 'red' }}
                        onClick={() => onDeleteFile(file.uuid)}
                        disabled={file.status === 'Approved' || offerCalculatorFactor.status === 'accepted'}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {errorMessage.show && (
        <Alert variant="filled" severity={errorMessage.succeed ? 'success' : 'error'}>
          {errorMessage.msg}
        </Alert>
      )}
      {submittedResult.msg && !submittedResult.succeed && (
        <Alert variant="filled" severity={submittedResult.succeed ? 'success' : 'error'}>
          {submittedResult.msg}
        </Alert>
      )}
    </>
  );
}
